import { Outlet } from "react-router";
import React from "react";
import LeftHandSide from "~/components/authentication/left-hand-side";

export default function Auth() {
  return (
    <div className="h-[100dvh] flex items-center justify-center bg-theme-background-grey-primary">
      <div className="h-full flex w-full justify-center items-center lg:grid grid-cols-4 lg:grid-cols-12">
        <div className="hidden lg:inline col-span-7 h-full">
          <div className="w-full h-full flex-col justify-center items-center">
            <LeftHandSide />
          </div>
        </div>
        <div className="h-full w-full lg:col-span-5 overflow-y-scroll">
          <div className="w-full h-full flex flex-col justify-between items-center relative px-10 lg:px-12 2xl:px-32 py-12 2xl:py-20">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
