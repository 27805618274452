import { useSearchParams } from "react-router";
import { motion, useAnimationControls } from "framer-motion";
import React, { useEffect } from "react";
import { classNames } from "~/utils";
import MindstoneLogoPurple from "../icons/mindstone-logo-purple";
import { Separator } from "@mindstonehq/ui";
import { VideoCameraIcon } from "@heroicons/react/24/outline";

export default function LeftHandSide() {
  const bgControls = useAnimationControls();
  const shapeAControls = useAnimationControls();
  const shapeBControls = useAnimationControls();
  const [searchParams] = useSearchParams();
  const isOnlineEventRedirect =
    searchParams.get("onlineEventRedirect") === "true";

  useEffect(() => {
    if (!isOnlineEventRedirect) {
      startTransition();
    }
  }, []);

  const startTransition = async () => {
    // GO TO PURPLE
    await shapeAControls.start({
      opacity: 0,
      transition: { duration: 0.5, delay: 6 },
    });
    await shapeAControls.start({ height: 0, transition: { duration: 0.1 } });
    await bgControls.start({
      backgroundColor: "#655c82",
      transition: { duration: 0.5 },
    });
    await shapeBControls.start({
      height: "100%",
      transition: { duration: 0.1 },
    });
    await shapeBControls.start({ opacity: 1, transition: { duration: 0.5 } });

    // GO TO GREEN
    await shapeBControls.start({
      opacity: 0,
      transition: { delay: 6, duration: 0.5 },
    });
    await shapeBControls.start({
      height: 0,
      transition: { duration: 0.1 },
    });
    await bgControls.start({
      backgroundColor: "#68a691",
      transition: { duration: 0.5 },
    });
    await shapeAControls.start({
      height: "100%",
      transition: { duration: 0.1 },
    });
    await shapeAControls.start({ opacity: 1, transition: { duration: 0.5 } });
    startTransition();
  };

  return (
    <>
      {isOnlineEventRedirect ? (
        <div
          className={classNames(
            "overflow-hidden min-h-screen h-full w-full bg-quaternary",
          )}
        >
          <div className="h-full w-full relative overflow-hidden">
            <Shape2 className="absolute w-[105%] inset-0 flex items-center justify-center text-quaternary-hover" />
            <Ellipse1 className="absolute p-16 w-full inset-0 flex items-center justify-center h-screen" />
            <div className="absolute inset-0 flex items-center justify-center text-center min-h-screen">
              <div className="flex flex-col items-center justify-center text-theme-primary-green-text-inverted max-w-sm">
                <h2 className="text-accent mb-3 px-4 flex items-center gap-6">
                  <VideoCameraIcon className="w-10 h-10 text-muted-foreground" />
                  <Separator orientation="vertical" className="w-1 h-8" />
                  <MindstoneLogoPurple />
                </h2>
                <p className="text-muted-foreground text-lg font-bold">
                  Ready for your live session?
                </p>
                <p className="text-muted-foreground text-sm w-72">
                  Simply fill out a few details so we can connect you to your
                  session.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          animate={bgControls}
          initial={{ backgroundColor: "#68a691" }}
          className={classNames("overflow-hidden min-h-screen h-full w-full")}
        >
          <motion.div
            key="a"
            animate={shapeAControls}
            className="h-full w-full relative overflow-hidden"
          >
            <Shape1 className="absolute w-[105%] inset-0 flex items-center justify-center text-[#599782]" />
            <Ellipse1 className="absolute p-16 w-full inset-0 flex items-center justify-center h-screen" />
            <div className="absolute inset-0 flex items-center justify-center text-center min-h-screen">
              <div className="flex flex-col items-center justify-center text-theme-primary-green-text-inverted max-w-sm">
                <h2 className="typography-heading-h3 xl:typography-heading-h2 mb-3 px-4">
                  AI-powered features
                </h2>
                <p className="xl:typography-body-lg-regular">
                  Track your skills as you actively learn with our AI-enabled
                  spaces
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="h-full w-full relative overflow-hidden opacity-0 h-0"
            key="b"
            animate={shapeBControls}
          >
            <Shape2 className="absolute w-[105%] inset-0 flex items-center justify-center text-[#312658]" />
            <Ellipse2 className="absolute p-16 w-full inset-0 flex items-center justify-center h-screen" />
            <div className="absolute inset-0 flex items-center justify-center text-center min-h-screen">
              <div className="flex flex-col items-center justify-center text-theme-primary-purple-text-inverted max-w-sm">
                <h2 className="typography-heading-h3 xl:typography-heading-h2 mb-3">
                  Build Practical AI Skills Beyond Certificates
                </h2>
                <p className="xl:typography-body-lg-regular">
                  Learn how to use AI for work and life
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
}

const Shape1 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      viewBox="0 0 799 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.383 54.3595C311.756 23.5464 340.157 0.00898 376.073 -7.48729C409.815 -14.5299 457.467 -21.0628 507.052 -16.697C554.779 -12.4949 622.324 3.53155 673.848 59.5007C723.166 113.073 777.757 181.973 794.451 284.916C808.208 369.752 787.385 447.121 773.586 498.389C773.294 499.473 773.005 500.546 772.72 501.606C754.155 570.669 720.898 656.313 632.202 713.232C602.832 732.08 561.721 758.286 516.508 778.212C471.713 797.953 412.811 816.226 347.789 809.733C267.981 801.763 195.369 770.58 133.848 722.55C84.2061 683.794 60.3812 625.225 51.58 603.589C50.6558 601.317 49.8973 599.453 49.2919 598.061C47.2291 593.319 44.6745 587.725 41.798 581.426C28.7616 552.88 9.11128 509.85 -1.35886 465.945C-10.2068 428.843 -16.2936 383.716 -14.2912 337.425C-12.3326 292.15 -2.20302 236.516 29.7683 187.162C60.7179 139.385 106.477 109.406 144.439 91.3012C183.773 72.542 226.579 60.8616 264.915 56.6395C275.474 55.4766 285.97 54.7306 296.383 54.3595Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Shape2 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      viewBox="0 0 815 775"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M727.078 248.564C758.857 259.657 785.194 284.281 796.908 318.176C807.913 350.021 820.145 395.415 822.049 444.032C823.882 490.829 816.703 558.303 768.813 615.188C722.975 669.637 662.926 731.096 565.18 760.024C484.626 783.863 407.033 773.249 355.616 766.216C354.529 766.067 353.453 765.92 352.389 765.775C283.129 756.322 195.972 734.676 129.806 655.723C107.897 629.579 77.3989 592.961 52.4829 551.589C27.7972 510.6 2.78958 455.751 1.03761 391.902C-1.11276 313.535 20.1337 239.271 59.0887 173.677C90.5222 120.747 144.361 90.3973 164.25 79.1858C166.338 78.0084 168.052 77.0422 169.327 76.283C173.669 73.6959 178.777 70.5267 184.528 66.958C210.593 50.7847 249.881 26.4061 291.155 10.8201C326.035 -2.35111 369.036 -13.8384 414.167 -17.6264C458.308 -21.3313 513.504 -18.3957 565.315 6.49476C615.469 30.5897 650.2 71.2466 672.453 105.814C695.511 141.63 712.134 181.688 720.973 218.336C723.407 228.43 725.429 238.514 727.078 248.564Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Ellipse1 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      width="653"
      height="664"
      viewBox="0 0 653 664"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.08 41.6482C329.893 -40.0493 524.386 23.7572 608.492 184.164C692.598 344.571 634.468 540.835 478.654 622.533C322.841 704.23 128.348 640.424 44.2419 480.017C-39.864 319.61 18.2664 123.346 174.08 41.6482ZM171.694 37.0982C329.949 -45.8792 527.489 18.9269 612.912 181.847C698.336 344.766 639.294 544.105 481.04 627.083C322.786 710.06 125.246 645.254 39.8222 482.334C-45.6013 319.415 13.4398 120.076 171.694 37.0982Z"
        fill="white"
      />
    </svg>
  );
};

const Ellipse2 = (props: React.ComponentProps<"svg">) => {
  return (
    <svg
      width="646"
      height="640"
      viewBox="0 0 646 640"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M585.607 136.302C684.105 277.265 646.312 473.741 501.193 575.144C356.074 676.546 158.583 644.476 60.0843 503.512C-38.4145 362.549 -0.621324 166.073 144.498 64.6701C289.617 -36.7324 487.108 -4.66186 585.607 136.302ZM589.723 133.425C689.765 276.597 651.379 476.151 503.987 579.142C356.595 682.133 156.01 649.56 55.9679 506.389C-44.0739 363.217 -5.68869 163.663 141.704 60.6716C289.096 -42.3194 489.681 -9.74649 589.723 133.425Z"
        fill="white"
      />
    </svg>
  );
};
